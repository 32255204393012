import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import bar90 from '../assets/img/bar90.png';
import bar80 from '../assets/img/bar80.png';
import bar70 from '../assets/img/bar70.png';
import bar85 from '../assets/img/bar85.png';
import bg_left from '../assets/img/background_left.png';
import TrackVisibility from 'react-on-screen';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? 'animate__animated animate__flipInX' : ''
                    }
                  >
                    <h2>Skills</h2>
                    <p>
                      Here you will find my proficiency in a variety of
                      programming languages and technologies. From Java and C#
                      to C and JavaScript, I am proficient in a variety of
                      programming languages and tools that allow me to develop
                      robust and efficient solutions. Each skill has a dynamic
                      proficiency bar, giving you an at-a-glance view of my
                      abilities.
                    </p>
                  </div>
                )}
              </TrackVisibility>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
              >
                <div className="item">
                  <img src={bar90} alt="Image" />
                  <h5>Java</h5>
                </div>
                <div className="item">
                  <img src={bar85} alt="Image" />
                  <h5>C#</h5>
                </div>
                <div className="item">
                  <img src={bar90} alt="Image" />
                  <h5>C</h5>
                </div>
                <div className="item">
                  <img src={bar80} alt="Image" />
                  <h5>Android Studio</h5>
                </div>
                <div className="item">
                  <img src={bar90} alt="Image" />
                  <h5>C++</h5>
                </div>
                <div className="item">
                  <img src={bar80} alt="Image" />
                  <h5>Python</h5>
                </div>
                <div className="item">
                  <img src={bar90} alt="Image" />
                  <h5>JavaScript</h5>
                </div>
                <div className="item">
                  <img src={bar70} alt="Image" />
                  <h5>Dart</h5>
                </div>
                <div className="item">
                  <img src={bar90} alt="Image" />
                  <h5>TypeScript</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={bg_left} alt="Image" />
    </section>
  );
};
