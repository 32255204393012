import { Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/img/logo.png';
import GitHub from '../assets/img/GitHub.png';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-item-center">
          <Col sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://github.com/Victoria-S-X">
                <img src={GitHub} />
              </a>
            </div>
            <p>CopyRight 2023. All Right Reserved by Victoria Xin</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
