import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const ProjectCard = ({ title, description, imgUrl, style, link }) => {
  return (
    <Col size={12} sm={8} md={10}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="project-card-link"
      >
        <div className="proj-imgbx" style={style}>
          <img
            src={imgUrl}
            alt={title}
            style={{ width: '100%', height: 'auto' }}
          />
          <div className="proj-txtx">
            <h4>{title}</h4>
            <span>{description}</span>
          </div>
        </div>
      </a>
    </Col>
  );
};

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  style: PropTypes.object,
  link: PropTypes.string.isRequired,
};

ProjectCard.defaultProps = {
  style: {},
};
