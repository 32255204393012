import { useState } from 'react';
import { Col, Container, Tab, Row, Nav } from 'react-bootstrap';
import { ProjectCard } from './ProjectCard';
import bg_right from '../assets/img/background_right.png';
import tHomerGitImg from '../assets/img/tHomer-optimized.gif';
import IoTDashboard from '../assets/img/dashboard.png';
import IoTDashDb from '../assets/img/database.png';
import IoTHardware from '../assets/img/hardware.jpeg';
import webTeaser from '../assets/img/webTeaser.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const [description, setDescription] = useState(
    'This project is a smart home security system that improves residential security by integrating hardware and software solutions. The system was written in Python. The security system can be activated or deactivated via a physical keypad and Node-Red dashboard switches. When activated, a motion sensor monitors the area and once movement is detected, a buzzer is triggered and a notification is displayed on the dashboard. The alarm can be deactivated by entering the correct password on the keypad, which can also be changed by the user via the dashboard, ensuring a convenient and secure real-time control and alarm environment for the user.'
  );
  const tHomerProject = {
    title: 'tHomer',
    description: 'Interactive SDL Homer with animated donut.',
    imgUrl: tHomerGitImg,
    style: { width: '100%', height: 'auto' },
    link: 'https://github.com/Victoria-S-X/tHomer',
  };

  const schoolWebProject = {
    title: 'Receipe4U',
    description:
      'Recipe sharing and instructional cooking courses with social interaction.',
    imgUrl: webTeaser,
    link: 'https://github.com/Victoria-S-X/Recipe4U?tab=readme-ov-file',
  };

  const IoTProject = [
    {
      title: 'Hardware Setup',
      description:
        'A smart home security system consisting of a Raspberry Pi Pico W, motion sensor, buzzer, LCD display and keypad.',
      imgUrl: IoTHardware,
      link: 'https://github.com/Victoria-S-X/IoT-smarthome?tab=readme-ov-file',
    },
    {
      title: 'Dashboard',
      description: 'The dashboard was built using Node-RED.',
      imgUrl: IoTDashboard,
      link: 'https://github.com/Victoria-S-X/IoT-smarthome?tab=readme-ov-file',
    },
    {
      title: 'Database',
      description: 'The project used the InfluxDB Cloud as the database.',
      imgUrl: IoTDashDb,
      link: 'https://github.com/Victoria-S-X/IoT-smarthome?tab=readme-ov-file',
    },
  ];

  const handleSelect = (key) => {
    let currentDescription = '';
    switch (key) {
      case 'first':
        currentDescription =
          'This project is a smart home security system that improves residential security by integrating hardware and software solutions. The system was written in Python. The security system can be activated or deactivated via a physical keypad and Node-Red dashboard switches. When activated, a motion sensor monitors the area and once movement is detected, a buzzer is triggered and a notification is displayed on the dashboard. The alarm can be deactivated by entering the correct password on the keypad, which can also be changed by the user via the dashboard, ensuring a convenient and secure real-time control and alarm environment for the user.';
        break;
      case 'second':
        currentDescription =
          "The project is a SDL application that displays a window with an image of Homer Simpson. It was written in C++. Homer's eyes follow the mouse cursor, and when the cursor is inside the window, a donut moves in place of the cursor icon with a spring-like animation effect. The program allows you to drag the window by holding down the left mouse button and pressing the “q” key to exit. You can also resize the window.";
        break;
      case 'third':
        currentDescription =
          'This project was a university group project to create a recipe sharing platform using Vue.js. Users can post and view recipes, create new accounts, and log in to interact with the community. Publishers can offer fixed-price instructional classes, both physically and remotely, to teach others how to cook the dishes they post. The platform includes a social component that allows chefs and food enthusiasts to connect and interact. The main pages include the home page, view all posts, create post, users (for published and registered courses), edit post, log in, register, and recipe posts, where users can register for courses, view recipes, and write or read comments.';
        break;
      default:
        currentDescription = 'Select below tabs to see projects.';
    }
    setDescription(currentDescription);
  };
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? 'animate__animated animate__slideInUp' : ''
                  }
                >
                  <h2>Projects</h2>
                  <p>{description}</p>
                </div>
              )}
            </TrackVisibility>
            <Tab.Container
              id="projects-tabs"
              defaultActiveKey="first"
              onSelect={handleSelect}
            >
              <Nav
                variant="pills"
                className="nav-pills mb-5 justify-content-center align-items-center"
                id="pills-tab"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Project One</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Project Two</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Project Three</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content id="slideInUp">
                <Tab.Pane eventKey="first">
                  <Row className="justify-content-center">
                    <Col
                      md={5}
                      className="d-flex justify-content-center iot-hardware"
                    >
                      <ProjectCard
                        {...IoTProject[0]}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />
                    </Col>
                    <Col md={7} className="iot-others">
                      <Row>
                        <Col
                          sm={10}
                          className="d-flex justify-content-center mb-4"
                        >
                          <ProjectCard
                            {...IoTProject[1]}
                            style={{ width: '100%', height: 'auto' }}
                          />
                        </Col>
                        <Col sm={10} className="d-flex justify-content-center">
                          <ProjectCard
                            {...IoTProject[2]}
                            style={{ width: '100%', height: 'auto' }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row className="justify-content-center">
                    <Col md={6} className="d-flex justify-content-center">
                      <ProjectCard key="gif" {...tHomerProject} />
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Row className="justify-content-center">
                    <ProjectCard
                      key="teaserImg"
                      {...schoolWebProject}
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={bg_right}></img>
    </section>
  );
};
