import { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import headerImg from '../assets/img/header.png';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { HashLink } from 'react-router-hash-link';
import { BrowserRouter as Router } from 'react-router-dom';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const toRotate = [
    'Backend Developer',
    'Frontend Developer',
    'Fullstack Developer',
  ];
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 130);
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => {
      clearInterval(ticker);
    };
  }, [text]);

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    }
  };

  return (
    <Router>
      <section className="banner" id="home">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={6} xl={7}>
              <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? 'animate__animated animate__fadeIn' : ''
                    }
                  >
                    <span className="tagline">Welcome to my Portfolio</span>
                    <h1>
                      {"Hi I'm Victoria! "}
                      <span className="wrap">{text}</span>
                    </h1>
                    <p>
                      I love software development and find endless joy in coding
                      and constantly learning new technologies and methods. I
                      find joy and fulfillment in both back-end and front-end
                      development, though I find back-end development more
                      appealing due to its complexity. I enjoy the challenge of
                      solving complex problems and optimizing algorithms to
                      improve program efficiency and performance. I am
                      passionate about creating robust, scalable and innovative
                      software solutions.
                    </p>
                    <HashLink style={{ textDecoration: 'none' }} to="#connect">
                      <button>
                        Let's connect <ArrowRightCircle size={25} />
                      </button>
                    </HashLink>
                  </div>
                )}
              </TrackVisibility>
            </Col>
            <Col xs={12} md={6} xl={3}>
              <TrackVisibility>
                {({ isVisible }) => (
                  <div
                    className={
                      isVisible ? 'animate__animated animate__zoomIn' : ''
                    }
                  >
                    <img src={headerImg} alt="Healder Img" />
                  </div>
                )}
              </TrackVisibility>
            </Col>
          </Row>
        </Container>
      </section>
    </Router>
  );
};
